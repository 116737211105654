import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'

import { Layout } from '../layouts/Layout'
import { SEO } from '../components/SEO'

//@ts-ignore
import monitor from '../images/monitor-window.svg'
//@ts-ignore
import ship from '../images/ship.svg'
//@ts-ignore
import stamp from '../images/stamp-document.svg'
import { githubPath } from '../utils/githubPath'

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > a {
    display: block;
    text-decoration: none;
    width: 350px;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    & > a {
      margin: auto;
    }
  }
`

const CardWrapper = styled.div`
  display: block;
  min-width: 250px;
  max-width: 350px;
  padding: 10px;
  text-align: center;
  text-decoration: none;

  @media (min-width: 768px) {
    padding: 20px;
  }
`

const Title = styled.span`
  display: block;
  font-family: sofia-pro, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #111;
  transition: color 200ms ease-in-out;

  ${CardWrapper}:hover & {
    color: ${COLORS.PRIMARY_BLUE};
  }

  @media (min-width: 768px) {
    font-size: 24px;
  }
`

type CardProps = {
  title: string
  imageSrc: string
}

function Card({ title, imageSrc }: CardProps): React.ReactElement<CardProps> {
  return (
    <CardWrapper>
      <img src={imageSrc} alt="" />
      <Title>{title}</Title>
    </CardWrapper>
  )
}

export default function HomePage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Home" />
      <h1 style={{ textAlign: 'center' }}>Welcome!</h1>
      <Grid>
        <Link to="/getting-started">
          <Card title="Getting started" imageSrc={ship} />
        </Link>
        <a href={githubPath('/releases')} target="_blank" rel="noreferrer noopener">
          <Card title="Changelog" imageSrc={stamp} />
        </a>
        <Link to="/components">
          <Card title="Components List" imageSrc={monitor} />
        </Link>
      </Grid>
    </Layout>
  )
}
